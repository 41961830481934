import { useState, useEffect } from 'react';
import 'bootstrap/dist/css/bootstrap.min.css';
import { BASE_API } from '../api/api';
import { Table } from 'react-bootstrap';
import SearchboxCard from '../AssetComponent/searchBoxCard';

function HiddenAllStaffs() {

    const [employees, setEmployees] = useState([]);

    const fetchData = async () => {
        const employeesResponse = await fetch(`${BASE_API}/api/employees/hidden`).then(res => res.json());
        setEmployees(employeesResponse);
    };

    function updateEmployeeSetActiveFalse(param) {
        return fetch(`${BASE_API}/api/employees/setActiveFalse/` + param)
          .then(function(result){
            return result;
        });
    }

    function updateemployeeSetActiveTrue(param) {
        return fetch(`${BASE_API}/api/employees/setActiveTrue/` + param)
          .then(function(result){
            return result;
        });
    }

    function changeButtonText(id, isHidden) {
        setEmployees(
            employees.map((item) => {
            if (item.EmpID === id) item.isHidden = isHidden;
            return item;
          })
        );
    };

    async function fetchDataByName(nameParam) {
        const employeesResponse = await fetch(`${BASE_API}/api/employees/hidden` + '/' + nameParam  )
        .then(res => res.json());
        setEmployees(employeesResponse); 
    };

    const searchDataHandler =  details => {
        console.log(details.name);
        fetchDataByName(details.name);
    }

    useEffect(() => {
        fetchData();
    }, []);

    return (
        <div>
            <div class="d-flex flex-md-column text-start text-md-center">              
                <SearchboxCard searchDataHandler={searchDataHandler} pageHeader='Hidden Staff'/>
                    <ul>
                        <Table striped bordered hover>
                            <thead>
                                <tr>
                                <th>Picture</th>
                                <th>First Name</th>
                                <th>Last Name</th>
                                <th>Email</th>
                                <th>Job Title</th>
                                <th>Location</th>
                                </tr>
                            </thead>
                            {employees.map((employee) => (
                            <tbody>
                                <tr>
                                    <td><img src={employee.PhotoUrl} width="105" height="90"   /></td>
                                    <td>{employee.FirstName}</td>
                                    <td>{employee.LastName}</td>
                                    <td>{employee.WorkEmail}</td>
                                    <td>{employee.JobTitle}</td>
                                    <td>{employee.Location}</td>
                                    <td>
                                        <button type="button" class="btn btn-success" onClick={() => 
                                            {
                                                employee.isHidden == 0 ?
                                                updateEmployeeSetActiveFalse(employee.EmpID) : updateemployeeSetActiveTrue(employee.EmpID);
                                                changeButtonText(employee.EmpID, employee.isHidden == 0 ? 1 : 0);
                                            }}>{employee.isHidden == 1 ? "Hidden from Website" : "Showing"}</button>
                                    </td>                                                         
                                </tr>
                            </tbody>
                            ))}
                        </Table> 
                    </ul>
            </div>
        </div>
    );
}
export default HiddenAllStaffs;