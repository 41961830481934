import '../../App.css';
import React from 'react';
import { useState, useEffect } from 'react';
import { BASE_API } from '../api/api';
import 'bootstrap/dist/css/bootstrap.min.css';
import '@fortawesome/fontawesome-free/css/all.min.css';
import { DropdownButton, ButtonGroup, Dropdown } from 'react-bootstrap';


function DropDownInput( {inputValue, dropDownDataHandler, itemArray}) {
    const [chosenValue, setchosenValue] = useState();
    const [status, setStatus] = useState('');

    const DdlHandle= async (e)=> {
        dropDownDataHandler(e);
    }
    
    return ( 
        <div className="locationDropDown">
            {['down'].map((direction) => (
                <DropdownButton
                    onSelect={DdlHandle}
                    className='btn-locdropLoc'
                    as={ButtonGroup}
                    key={direction}
                    id={`dropdown-button-drop-${direction}`}
                    drop={direction}
                    variant="secondary"
                    title={`${inputValue ? inputValue : 'Select Dep..'} `}
                >
                    {itemArray?.map((item) => (
                        <Dropdown.Item eventKey={item.itemName} key={item.itemID}>{item.itemName == null ? "Not Set" : item.itemName}</Dropdown.Item>              
                    ))}
                </DropdownButton>
            ))}
            {/*<p class="text-success">{status}</p>*/}
        </div> 
    );
}
export default DropDownInput;