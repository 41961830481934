import React from 'react';
import 'bootstrap/dist/css/bootstrap.min.css';
import 'bootstrap/dist/js/bootstrap.bundle.min.js'; // without this two responsive nav will not work
import 'bootstrap/dist/js/bootstrap.min.js'; // without this two responsive nav will not work
import '@fortawesome/fontawesome-free/css/all.min.css';
import logo from '../../images/footerLogoNew.png';
import { useState, useEffect } from 'react';

function NavPage(props) {
    const [isLoggedIn, setIsLoggedIn] = useState(false);
    const [value, setValue] = useState({});
    //const [stickyClass, setStickyClass] = useState('');

    // useEffect(()=>{
    //     //setIsLoggedIn(Authservice.getCurrentUser);
    //     setValue({});
    // })
    // useEffect(() => {
    //     window.addEventListener('scroll', stickNavbar);
    //     return () => window.removeEventListener('scroll', stickNavbar);
    //   }, []);
    
    //   const stickNavbar = () => {
    //     if (window !== undefined) {
    //       let windowHeight = window.scrollY;
    //       // window height changed for the demo
    //       windowHeight > 150 ? setStickyClass('sticky-nav') : setStickyClass('');
    //     }
    //   };

    return ( 
    <nav class="navbar transparent fixed-top navbar-expand-lg navbar-dark ">
        <div class="container-fluid">
            <span className="navbar-brand mr-3">
                <img src={logo} className="cursor-pointer" />
            </span>
            <button class="navbar-toggler" type="button" data-bs-toggle="collapse" data-bs-target="#navbarSupportedContent"
                aria-controls="navbarSupportedContent" aria-expanded="true" aria-label="Toggle navigation">
                <span class="navbar-toggler-icon">Menu
                <i className="fas fa-bars"></i></span>
            </button>
            <div class="collapse navbar-collapse" id="navbarSupportedContent">
                <ul class="navbar-nav ms-auto mb-2 mb-lg-0">             
                    <li class="nav-item">
                        <a class="nav-link " href="/allstaffs"><i class="fas fa-info"></i> Staff 401 </a>
                    </li>
                    {/*<li class="nav-item">
                        <a class="nav-link" href="/api"><i class="fas fa-project-diagram"></i> API </a>
                    </li> 
                    <li class="nav-item">
                        <a class="nav-link" href="/settings"><i class="fa-solid fa-gear"></i> Settings </a>
                    </li>*/}
                    {props.isAuth &&
                        <li class="nav-item">
                        <a class="nav-link" href="/logout"><i class="fa fa-sign-out" aria-hidden="true"></i> Log out </a>
                    </li>
                    }               
                </ul>
            </div>
        </div>
    </nav>
    );
}
export default NavPage;