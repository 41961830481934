//import React from 'react';
import LoginForm from './LoginForm';
import Auth from './Auth';
import { Navigate } from "react-router-dom";
import { React, useState, useContext } from 'react';
import { UserContext } from '../UserContext';
import { BASE_API } from '../api/api';

function LogInPage(props) {
  
      const [user, setUser] = useState({name:"", password:""});
      const [error, setError] = useState("");
      const {isAuthenticated, setisAuthenticated} = useContext(UserContext);
      //const [adminuser, setAdminUser] = useState({name:"", password:""});

      const fetchLogInData = async(name, password) => {
       
        const data = { 
                username: name,
                password: password 
              };
        console.log('before api call');
        //console.log(details);
        console.log(data);
        console.log('----------');
        await fetch(`${BASE_API}/api/login`, {
            method: 'POST', 
            headers: {
              'Content-Type': 'application/json',
            },
            body: JSON.stringify(data),
          }).then(res => res.json())
          .then(function(result){
            console.log('from response - line 37');
            console.log(result);
            console.log(result.auth);
            console.log('----------');
            if(result?.auth)
            {
              // console.log(Auth.isAuthenticated());
              // console.log('---------------------');
              Auth.login();
              setisAuthenticated(true);
              //window.localStorage.setItem("isLoggedIn", true);
              window.sessionStorage.setItem("isLoggedIn", true);
              console.log('---------------after set auth value ----------if');
              console.log(window.sessionStorage.getItem("isLoggedIn"));
              window.sessionStorage.removeItem("isError");
            }
            else{
              Auth.logout();
              setisAuthenticated(false);
              window.sessionStorage.setItem("isError", result.message);
              console.log(result.message);
              //window.localStorage.removeItem("isLoggedIn");
              // console.log('---------------after set auth value ----------else');
              // console.log(isAuthenticated);
            }
            // console.log('----------');
            // console.log('----------');
            return result;
        });
      };
   
      const Login =  details => {
        // console.log(details);
        // console.log('from log in ');
        // console.log(Auth.isAuthenticated());
        
        fetchLogInData(details.name, details.password)
        .then(() => {
          console.log('from line 72');
        console.log('----------');
        const loggedIn = window.sessionStorage.getItem("isLoggedIn");
        console.log(Auth.isAuthenticated());
        console.log('local - '+loggedIn);
    
        if(loggedIn)
        {
          console.log('Logged in success');
          setUser({
            name:details.name,
            password:details.password
          });         
          //console.log("from login page "+isAuthenticated);
        }
        else{
          console.log('from line 85');
          console.log(window.sessionStorage.getItem("isError"));
          setError(error);
        };    
      })  
      }

    return (
        <div className="LogInPage">
          {(user.name !="") ?
          (
            <Navigate  to="/activeAllStaff"/>
            
          ) : (
            <LoginForm Login={Login} error={error}/>
          )}
        </div>
      );
}
export default LogInPage;