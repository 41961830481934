import React, { useState, useEffect } from 'react';
import 'bootstrap/dist/css/bootstrap.min.css';
import { Table } from 'react-bootstrap';
import {DragDropContext, Droppable, Draggable} from 'react-beautiful-dnd';
import { BASE_API } from '../api/api';
import SearchboxCard from './searchBoxCard';
import DropDownControl from './DropDownControl';
import CustomDropDownControl from './CustomDropDownControl';

//helper class used to load employee by location for different location
function StaffPageBody(props) {
    const [employees, setEmployees] = useState([]);
    const [name, setName] = useState("");
    const [isSuccess, setisSuccess] = useState(false);
    const [status, setStatus] = useState('');
    const [deptStatus, setDeptStatus] = useState('');
    const [saveStatus, setSaveStatus] = useState("");
    const [departments, setDepartment] = useState([]);

    //function to handle column drag
    function handleOnDragEnd(result)
    {
        if(!result.destination) return;
        const items = Array.from(employees);
        const [reorderedItem] = items.splice(result.source.index, 1);
        items.splice(result.destination.index, 0, reorderedItem);
        setEmployees(items);
        setSaveStatus("Save staff changes");
    }

    const fetchData = async () => {
        const employeesResponse = await fetch(`${BASE_API}/api/employees/location/`+props.location).then(res => res.json());
        setEmployees(employeesResponse);
        console.log(employeesResponse);
    };

    async function fetchDataByName(param) {
        console.log(`${BASE_API}/api/employees/location/`+props.location+ '/' + param);
        const employeesResponse = await fetch(`${BASE_API}/api/employees/location/`+props.location+ '/' + param)
        .then(res => res.json());
        setEmployees(employeesResponse);
        console.log(employeesResponse);
    };

    const fetchDeptData = async () => {
        const deptResponse = await fetch(`${BASE_API}/api/department/allDepartment`).then(res => res.json());
        setDepartment(deptResponse);
        console.log(deptResponse);
    };

    function updateStaffSortIDByLoc(empIdparam, sortIdparam) {
        return fetch(`${BASE_API}/api/employees/updateStaffSortIDByLoc/` + empIdparam + '/' +sortIdparam)
        .then(res => res.json())
        .then(function(result){
            if(result ==="success")
            {
                setStatus('Records updated');
                //console.log(empIdparam+' - '+isSuccess);
            }
            else
            {
                setisSuccess(false);
                setStatus('Errors updating records...');
            }
            return result;
        });
    }

    function updateEmployeeSetActiveFalse(param) {
        return fetch(`${BASE_API}/api/employees/setActiveFalse/` + param)
          .then(function(result){
            return result;
        });
    }

    function updateemployeeSetActiveTrue(param) {
        return fetch(`${BASE_API}/api/employees/setActiveTrue/` + param)
          .then(function(result){
            return result;
        });
    }

    function changeButtonText(id, isHidden) {
        setEmployees(
            employees.map((item) => {
            if (item.EmpID === id) item.isHidden = isHidden;  
            return item;
          })
        );
    };

    function updateStaffDept(id, dept) {
        return fetch(`${BASE_API}/api/employees/updatestaffdept/` + id + '/' +dept)
        .then(res => res.json())
        .then(function(result){
            if(result ==="success")
            {
                setDeptStatus('Records updated');
                setEmployees(
                    employees.map((item) => {
                    if (item.EmpID === id) 
                    {
                        item.NewDepartment = dept;
                        item.Status = deptStatus;
                    }
                    return item;
                  })
                );
                
                //setSaveStatus("Save staff changes");
                //console.log(empIdparam+' - '+isSuccess);
            }
            else
            {
                //setisSuccess(false);
                setDeptStatus('Errors updating records...');
                setEmployees(
                    employees.map((item) => {
                    if (item.EmpID === id) 
                    {
                        item.NewDepartment = dept;
                        item.Status = deptStatus;
                    }
                    return item;
                  })
                );
            }
            return result;
        });
    }

    function updateSortId(id, SortID) {
        setEmployees(
            employees.map((item) => {
            if (item.EmpID === id) item.StaffSortID = SortID;
            return item;
          })
        );
    };

    const searchDataHandler =  details => {
        console.log(details.name);
        fetchDataByName(details.name); 
    }

    useEffect(() => {
        fetchData();
        fetchDeptData();
    }, []);

    return (
        <div>
            <div class="d-flex flex-md-column text-start text-md-center">
                <SearchboxCard searchDataHandler={searchDataHandler} pageHeader={props.location}/>
                <Table striped bordered hover>
                    <thead>
                        <tr>
                            <th>Picture</th>
                            <th>First Name</th>
                            <th>Last Name</th>
                            <th>Email</th>
                            <th>Job Title</th>
                            <th>Department</th>
                             <th>Location</th>
                        </tr>
                    </thead>                 
                    <DragDropContext onDragEnd={handleOnDragEnd}>                                  
                        <Droppable droppableId='characters'>
                            {(provided) => (
                                <tbody className='characters' {...provided.droppableProps} ref={provided.innerRef}>
                                    {employees.map((employee, index) => {
                                        return (
                                            <Draggable key={employee.EmpID} draggableId={employee.EmpID.toString()} index={index}>
                                                {(provided) => (
                                                    <tr {...provided.draggableProps} {...provided.dragHandleProps} ref={provided.innerRef}>
                                                        <td><img src={employee.PhotoUrl} width="105" height="90"   /></td>
                                                        <td>{employee.FirstName}</td>
                                                        <td>{employee.LastName}</td>
                                                        <td>{employee.WorkEmail}</td>     
                                                        <td>{employee.JobTitle}</td>
                                                        <td>{employee.Department}
                                                        {/*<CustomDropDownControl valueParam={employee.NewDepartment} listParam={departments} listItem='dept'
                                                         id={employee.EmpID} dropDownDataHandler={(e) => {
                                                            updateStaffDept(employee.EmpID, e);
                                                           
                                                            
                                                          }}/>
                                                           <p class="text-success">{employee.Status}</p>*/}
                                                        </td>
                                                        <td>
                                                        {employee.Location}
                                                            <DropDownControl location={employee.NewLocation} employee = {employee.EmpID} locations={props.locations}/>
                                                        </td>
                                                        <td>
                                                            <button type="button" class="btn btn-success" onClick={() => {
                                                                employee.isHidden == 0 ?
                                                                updateEmployeeSetActiveFalse(employee.EmpID) : updateemployeeSetActiveTrue(employee.EmpID);
                                                                changeButtonText(employee.EmpID, employee.isHidden == 0 ? 1 : 0);
                                                            }}>
                                                                {employee.isHidden == 1 ? "Hidden from Website" : "Showing"}
                                                            </button>
                                                        </td>                                                                                                    
                                                    </tr>
                                                )}
                                            </Draggable>
                                        );
                                    })}
                                </tbody>
                            )}
                        </Droppable>
                    </DragDropContext> 
                </Table>
                <div class="d-flex justify-content-end fixed-bottom">
                    <button type="button" class="btn btn-danger" onClick={() => {
                        employees.map((item, index) => {
                            updateStaffSortIDByLoc(item.EmpID, index);
                            //console.log(item);
                        })
                        setSaveStatus('Changes Saved');
                    }}> <i class="fas fa-save"></i> Save changes</button>
                </div>
                <h4 class="d-flex justify-content-center">{status}</h4> 
            </div>
            <div className='allLocationBtnFloat'>
                    <div class="d-flex justify-content-start fixed-top" id='allLocationBtnFloat'>
                    <h5 className='pendinngCountDiv'>{saveStatus}</h5>
                    </div>
                </div>
        </div>
    );
}
export default StaffPageBody;