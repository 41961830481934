import React, {useState} from 'react';
import { BASE_API } from '../api/api';

function AddLocation() {
  const [details, setDetails] = useState({location:"", sortId:""});
  const [status, setStatus] = useState('');

  function InsertLocation(locparam, sortIdparam) {
    console.log('insert location function called');
    return fetch(`${BASE_API}/api/locations/insertlocation/` + locparam + '/' +sortIdparam)
      .then(function(result){
        setStatus( locparam + ' Added');
        return result;
    });
  }

  const submitHandler = e => {
    e.preventDefault();
    InsertLocation(details.location, details.sortId);
  }
  
  return (
    <div className="addLocation">
      <form onSubmit={submitHandler}>
        <div className='form-inner'>
          <h2>Add Location</h2> 
          <div class="form-group">
            <label for="name">Location </label>
            <input type="text" name="name" id="name" 
              onChange={e => setDetails({...details, location: e.target.value})} value={details.location}/>
          </div>
          <div class="form-group">
            <label for="password">Sort Id </label>
            <input type="text" name="password" id="password" 
              onChange={e => setDetails({...details, sortId: e.target.value})} value={details.sortId}/>
          </div>
          <br/>
          <input type="submit" value="Add" />
          <p class="text-success">{status}</p>
        </div>
      </form>
    </div>
  );
}
export default AddLocation;