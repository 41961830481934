import { useState, useEffect } from "react";
import "bootstrap/dist/css/bootstrap.min.css";
import { BASE_API } from "../api/api";
import { Table } from "react-bootstrap";
import React from "react";
import { useNavigate } from "react-router-dom";
import { DragDropContext, Droppable, Draggable } from "react-beautiful-dnd";
import "./location.css";

function AllLocations_401() {
  const [locations, setlocations] = useState([]);
  const [status, setStatus] = useState("");
  const navigate = useNavigate();

  const fetchData = async () => {
    const locationsResponse = await fetch(
      `${BASE_API}/api/locations/allLocations`
    ).then((res) => res.json());
    setlocations(locationsResponse);
    console.log(locationsResponse);
  };

  function updateLocation(locIdparam, sortIdparam, isActiveparam, name) {
    return fetch(
      `${BASE_API}/api/locations/allLocations401/` +
        locIdparam +
        "/" +
        sortIdparam +
        "/" +
        isActiveparam +
        "/" +
        name
    )
      .then((res) => res.json())
      .then(function (result) {
        console.log(result);
        if (result === "success") {
          setStatus("Records updated");
        } else {
          setStatus("Errors updating records...");
        }
        return result;
      });
  }

  const refreshData = async () => {
    await fetch(`${BASE_API}/api/locations/refreshlocation`).then(() => {
      setStatus("Location updated");
      fetchData();
    });
  };

  function updateSortId(id, LocSortID) {
    setlocations(
      locations.map((item) => {
        if (item.LoctID === id) item.locSortID_401Auto = LocSortID;
        return item;
      })
    );
  }

  function updatelocationStatus(id, isActive) {
    setlocations(
      locations.map((item) => {
        if (item.LoctID === id) item.isActive_401Auto = isActive;
        return item;
      })
    );
  }

  function updateLocationName(id, name) {
    setlocations(
        locations.map((item) => {
        if (item.LoctID === id) {
            item.LocationName_401Auto = name;
        }
        return item;
      })
    );
  }

  function handleOnDragEnd(result) {
    if (!result.destination) return;
    const items = Array.from(locations);
    const [reorderedItem] = items.splice(result.source.index, 1);
    items.splice(result.destination.index, 0, reorderedItem);
    setlocations(items);
  }

  const navigateToAddLocation = () => {
    // 👇️ navigate to /add location
    navigate("/addLocation");
  };

  useEffect(() => {
    fetchData();
  }, []);

  return (
    <div className="locationContainer_401">
      <div class="d-flex flex-md-column text-start text-md-center">
        <h2>All Locations - 401 Auto</h2>
        <p class="text-success">{status}</p>
        <Table striped bordered hover>
          <thead>
            <tr>
              <th>Sort Num</th>
              <th>Location</th>
              <th>Location Name(401)</th>
              <th>Active(401)</th>
            </tr>
          </thead>
          <DragDropContext onDragEnd={handleOnDragEnd}>
            <Droppable droppableId="characters">
              {(provided) => (
                <tbody
                  className="characters"
                  {...provided.droppableProps}
                  ref={provided.innerRef}
                >
                  {locations.map((locations, index) => {
                    return (
                      <Draggable
                        key={locations.LoctID}
                        draggableId={locations.LoctID.toString()}
                        index={index}
                      >
                        {(provided) => (
                          <tr
                            {...provided.draggableProps}
                            {...provided.dragHandleProps}
                            ref={provided.innerRef}
                          >
                            <td>{locations.locSortID_401Auto}</td>
                            <td>
                              {locations.Locations == null
                                ? "Location not Set"
                                : locations.Locations}
                            </td>
                            <td>
                              <input
                                type="text"
                                value={
                                    locations.LocationName_401Auto == null
                                    ? ""
                                    : locations.LocationName_401Auto
                                }
                                id="inputParam_AllLocation401"
                                onChange={(e) =>
                                    updateLocationName(
                                    locations.LoctID,
                                    e.target.value
                                  )
                                }
                              />
                            </td>
                            <td>
                              <input
                                class="form-check-input"
                                type="checkbox"
                                checked={locations.isActive_401Auto}
                                id="flexCheckDefault"
                                onChange={(e) =>
                                  updatelocationStatus(
                                    locations.LoctID,
                                    e.target.checked
                                  )
                                }
                              />
                            </td>
                          </tr>
                        )}
                      </Draggable>
                    );
                  })}
                </tbody>
              )}
            </Droppable>
          </DragDropContext>
        </Table>
        <div className="allLocationBtnFloat">
          <div class="d-flex justify-content-end fixed-bottom">
            <button
              class="btn btn-secondary"
              type="button"
              data-toggle="tooltip"
              data-placement="right"
              title="Click to refresh location"
              onClick={refreshData}
            >
              <i class="fa fa-refresh"></i> Refresh Location
            </button>
            <button
              type="button"
              class="btn btn-success"
              data-toggle="tooltip"
              data-placement="right"
              title="Click to save location update"
              onClick={() => {
                locations.map((item, index) => {
                  const setR = updateLocation(
                    item.LoctID,
                    index,
                    item.isActive_401Auto ? 1 : 0,
                    item.LocationName_401Auto
                  );
                });
              }}
            >
              {" "}
              <i class="fas fa-save"></i> Save changes
            </button>
            <button
              type="button"
              id="AllLocation_BtnRight"
              class="btn btn-danger"
              data-toggle="tooltip"
              data-placement="right"
              title="Click to Add new location"
              onClick={navigateToAddLocation}
            >
              <i class="fa-solid fa-plus"></i> Add location
            </button>
          </div>
        </div>
      </div>
    </div>
  );
}

export default AllLocations_401;
