import { useState, useEffect } from "react";
import "bootstrap/dist/css/bootstrap.min.css";
import { Table } from "react-bootstrap";
import { BASE_API } from "../api/api";
import { DragDropContext, Droppable, Draggable } from "react-beautiful-dnd";
import SearchboxCard from "../AssetComponent/searchBoxCard";
import Row from "react-bootstrap/Row";
import Col from "react-bootstrap/Col";
import DropDownControl401 from "../AssetComponent/DropDownControl401";
import { DropdownButton, ButtonGroup, Dropdown } from "react-bootstrap";
import "./allStaff401.css";

function AllStaff401(props) {
  const [employees, setEmployees] = useState([]);
  const [updateAll, setUpdateAll] = useState(false);
  const [name, setName] = useState("");
  const [chosenValue, setchosenValue] = useState();
  const [status, setStatus] = useState("");

  const fetchData = async () => {
    const employeesResponse = await fetch(
      `${BASE_API}/api/employees/401team/allstaff401`
    ).then((res) => res.json());
    setEmployees(employeesResponse);
    console.log(employeesResponse);
  };

  function updateEmployeeSetActiveFalse(param) {
    return fetch(`${BASE_API}/api/employees/setActiveFalse/` + param).then(
      function (result) {
        return result;
      }
    );
  }

  function updateemployeeSetActiveTrue(param) {
    return fetch(`${BASE_API}/api/employees/setActiveTrue/` + param).then(
      function (result) {
        return result;
      }
    );
  }

  async function UpdateAllRow() {
    const dataRow = [];
    employees.map((item, index) => {
      let data = { id: item.EmpID, sortId: index };
      dataRow.push(data);
    });
    return await fetch(`${BASE_API}/api/employees/updateemployeesortid`, {
      method: "POST",
      headers: {
        "Content-Type": "application/json",
      },
      body: JSON.stringify(dataRow),
    })
      .then((res) => res.json())
      .then(function (result) {
        console.log(result?.UpdateCount);
        if (result[0]?.UpdateCount) {
          setStatus("Records updated - " + result[0]?.UpdateCount);
          setUpdateAll(false);
          FetchEmployeeByLocation(chosenValue);
        }

        return result;
      });
  }

  async function UpdateRowDirty() {
    //const dataRow = employees.filter((item)=> item.isDirty === true);
    const dataRow = [];
    employees
      .filter((item) => item.isDirty === true)
      .map((item, index) => {
        let data = {
          id: item.EmpID,
          name401: item.Name401,
          title401: item.Title401,
        };
        dataRow.push(data);
      });

    //console.log(dataRow?.length);
    dataRow?.length > 0 ? UpdateDataBulk(dataRow) : "";
  }

  const UpdateDataBulk = async (dataRow) => {
    return await fetch(
      `${BASE_API}/api/employees/updateemployee401autopagejson`,
      {
        method: "POST",
        headers: {
          "Content-Type": "application/json",
        },
        body: JSON.stringify(dataRow),
      }
    )
      .then((res) => res.json())
      .then(function (result) {
        if (result?.UpdateCount) {
          setStatus("Records updated - " + result?.UpdateCount);
          setUpdateAll(false);
        }
        return result;
      });
  };

  function handleOnDragEnd(result) {
    setStatus("");
    if (!result.destination) return;
    const items = Array.from(employees);
    const [reorderedItem] = items.splice(result.source.index, 1);
    items.splice(result.destination.index, 0, reorderedItem);
    setEmployees(items);
    setUpdateAll(true);
  }

  function changeButtonText(id, isHidden) {
    setEmployees(
      employees.map((item) => {
        if (item.EmpID === id) item.isHidden = isHidden;
        return item;
      })
    );
  }

  const searchDataHandler = (details) => {
    setName(details.name);
    fetchDataByName(details.name);
  };

  async function fetchDataByName(nameParam) {
    setName('');
    const employeesResponse = await fetch(
      `${BASE_API}/api/employees/401team/getemaployeebysearchparam/${nameParam}`
    )
      .then((res) => res.json());
      setEmployees(employeesResponse);
  }

  function updateEmployee(id, param, paramField) {
    setStatus("");
    setName('');
    setEmployees(
      employees.map((item) => {
        if (item.EmpID === id) {
          paramField === "name"
            ? (item.Name401 = param)
            : (item.Title401 = param);
          item.isDirty = true;
        }
        return item;
      })
    );
  }
  async function updateEmployeeDeletStatus(dataRow) {
    return await fetch(`${BASE_API}/api/employees/updatedeletestatus401auto`, {
        method: "POST",
        headers: {
          "Content-Type": "application/json",
        },
        body: JSON.stringify(dataRow),
      })
        .then((res) => res.json())
        .then(function (result) {
          if (result?.UpdateCount) {
            setStatus("Records updated - " + result?.UpdateCount);
            setUpdateAll(false);
          }
          return result;
        })
}

  function updateDeleteStatus(id) {
    setStatus("");
    setEmployees(
      employees.map((item) => {
        if (item.EmpID === id) {
          item.isDeleted = true;
          item.isActive = false;
          item.isHidden = true;
        }
        return item;
      })
    );
    let data = { id: id, isDeleted: true, isActive: false, isHidden: true };
    updateEmployeeDeletStatus(data); //delete false = 0, true = 1
    //updateEmployeeDeletStatus(id, 1); //delete = 1
  }

  useEffect(() => {
    //fetchData();
  }, []);

 
  const DdlHandle = async (e) => {
    setchosenValue(e);
    FetchEmployeeByLocation(e);
  };

  async function FetchEmployeeByLocation(locParam) {
    const employeesResponse = await fetch(
      `${BASE_API}/api/employees/401team/getemaployeebylocation/${locParam}`
    )
      .then((res) => res.json())
      .then((result) => {
        console.log(result);
        setEmployees(result);
      });
  }

  return (
    <div>
      <div class="d-flex flex-md-column text-start text-md-center">
        <Row>
          <Col xs={12} sm={12} md={8} lg={7}>
            <SearchboxCard
              searchDataHandler={searchDataHandler}
              pageHeader="Team 401"
            />
          </Col>
          <Col xs={12} sm={12} md={8} lg={2}>
            {["down"].map((direction) => (
              <DropdownButton
                onSelect={DdlHandle}
                className="btn-locdropLoc"
                as={ButtonGroup}
                key={direction}
                id={`dropdown-button-drop-${direction}`}
                drop={direction}
                variant="secondary"
                title={`${chosenValue ? chosenValue : "Select Location"} `}
              >
                {props.locations?.filter((f)=> f.isActive_401Auto === true).map((location) => (
                  <Dropdown.Item
                    eventKey={location.Locations}
                    key={location.LoctID}
                  >
                    {location.Locations == null
                      ? "Location not Set"
                      : location.Locations}
                  </Dropdown.Item>
                ))}
              </DropdownButton>
            ))}
          </Col>
          <Col xs={12} sm={12} md={8} lg={2}>
          <button
            type="button"
            class="btn btn-success"
            onClick={fetchData}
          >
            {" "}
            Show All
          </button>
          </Col>
        </Row>
        {/*<SearchboxCard
          searchDataHandler={searchDataHandler}
          pageHeader="Team 401"
        />*/}
        <div id="AllStaffPage_401">
          <Table striped bordered hover id="AllStaffPage_401_Table">
            <thead>
              <tr>
                <th id="AllStaff_Table_Th">Sort Id</th>
                <th id="AllStaff_Table_Th">Picture</th>
                <th id="AllStaff_Table_Th">Name HR</th>
                <th id="AllStaff_Table_Th">Job Title HR</th>
                <th id="AllStaff_Table_Th">Location HR</th>
                <th id="AllStaff_Table_Th">Name</th>
                <th id="AllStaff_Table_Th">Job Title</th>
                <th>401 Location</th>
                <th>Loc Name</th>
                {/*<th>Active</th>*/}
              </tr>
            </thead>
            <DragDropContext onDragEnd={handleOnDragEnd}>
              <Droppable droppableId="characters">
                {(provided) => (
                  <tbody
                    className="characters"
                    {...provided.droppableProps}
                    ref={provided.innerRef}
                  >
                    {employees
                      .map((employee, index) => {
                        return (
                          <Draggable
                            key={employee.EmpID}
                            draggableId={employee.EmpID.toString()}
                            index={index}
                          >
                            {(provided) => (
                              <tr
                                {...provided.draggableProps}
                                {...provided.dragHandleProps}
                                ref={provided.innerRef}
                              >
                                <td id="AllStaff_Table_Td">
                                {employee?.Sort_ID}
                              </td>
                                <td>
                                    
                                  <img
                                    src={employee?.PhotoUrl}
                                    width="55"
                                    height="45"
                                    referrerpolicy="no-referrer"
                                  />
                                </td>
                                <td id="AllStaff_Table_Td">
                                  {employee.DisplayName}
                                </td>
                                <td id="AllStaff_Table_Td_title">
                                  {employee.JobTitle}
                                </td>
                                <td id="AllStaff_Table_Td">
                                  {employee.Location}
                                </td>
                                <td>
                                  <input
                                    type="text"
                                    value={employee.Name401}
                                    id="inputParam_AllLocation401"
                                    onChange={(e) =>
                                      updateEmployee(
                                        employee.EmpID,
                                        e.target.value,
                                        "name"
                                      )
                                    }
                                  />
                                </td>
                                <td>
                                  <input
                                    type="text"
                                    value={employee.Title401}
                                    id="inputParam_AllLocation401"
                                    onChange={(e) =>
                                      updateEmployee(
                                        employee.EmpID,
                                        e.target.value,
                                        "title"
                                      )
                                    }
                                  />
                                </td>
                                <td id="AllStaff_Table_Td_Loc">
                                  <DropDownControl401
                                    location={employee.Location401}
                                    employee={employee.EmpID}
                                    locations={props.locations}
                                  />
                                </td>
                                <td id="AllStaff_Table_Td">
                                  {employee.LocationName_401Auto}
                                </td>
                                <td id="AllStaff_Table_Td_Status">
                                {employee.isDeleted ? '' :
                                  <button
                                    type="button"
                                    class="btn btn-success"
                                    onClick={() => {
                                        setStatus("");
                                      employee.isHidden == 0
                                        ? updateEmployeeSetActiveFalse(
                                            employee.EmpID
                                          )
                                        : updateemployeeSetActiveTrue(
                                            employee.EmpID
                                          );
                                      changeButtonText(
                                        employee.EmpID,
                                        employee.isHidden == 0 ? 1 : 0
                                      );
                                    }}
                                  >
                                    {employee.isHidden == 1 ? (
                                      <i class="fa-solid fa-eye-slash"></i>
                                    ) : (
                                      <i class="fa-solid fa-eye"></i> 
                                    )}
                                  </button>}
                                </td>
                                <td id="AllStaff_Table_Td_Status">
                                  {!employee.isDeleted ? (
                                    <button
                                      type="button"
                                      class={"btn btn-danger"}
                                      onClick={() => {
                                        updateDeleteStatus(employee.EmpID);
                                      }}
                                    >
                                      <i
                                        class="fa fa-trash"
                                        aria-hidden="true"
                                      ></i>
                                    </button>
                                  ) : (
                                    ""
                                  )}
                                </td>
                              </tr>
                            )}
                          </Draggable>
                        );
                      })}
                  </tbody>
                )}
              </Droppable>
            </DragDropContext>
          </Table>
        </div>
        <div class="d-flex justify-content-end fixed-bottom">
          <button
            type="button"
            class="btn btn-danger"
            onClick={() => {
              updateAll
                ? UpdateAllRow()
                : UpdateRowDirty();
            }}
          >
            {" "}
            <i class="fas fa-save"></i> Save changes
          </button>
        </div>
        <h4 class="d-flex justify-content-center">{status}</h4>
      </div>
      <div className='allLocationBtnFloat'>
                    <div class="d-flex justify-content-start fixed-top" id='allLocationBtnFloat'>
                    <h5 className='pendinngCountDiv'>Total {employees.length}</h5>
                    </div>
                </div>
    </div>
  );
}

export default AllStaff401;
