import { useState, useEffect } from 'react';
import 'bootstrap/dist/css/bootstrap.min.css';
import { BASE_API } from '../api/api';
import { Table } from 'react-bootstrap';
import React from 'react';
import { useNavigate } from 'react-router-dom';
import {DragDropContext, Droppable, Draggable} from 'react-beautiful-dnd';

function AllLocations() {

    const [locations, setlocations] = useState([]);
    const [status, setStatus] = useState('');
    const navigate = useNavigate();

    const fetchData = async () => {
        const locationsResponse = await fetch(`${BASE_API}/api/locations/allLocations`).then(res => res.json());
        setlocations(locationsResponse);
    };

    function updateLocation(locIdparam, sortIdparam, isActiveparam) {
        return fetch(`${BASE_API}/api/locations/allLocations/` + locIdparam + '/' +sortIdparam+ '/' + isActiveparam)
        .then(res => res.json())
        .then(function(result) {
            console.log(result);
            if(result ==="success")
            {
                setStatus('Records updated');
            }
            else
            {
                setStatus('Errors updating records...');
            }
            return result;
        });
    }

    const refreshData = async () => {
        await fetch(`${BASE_API}/api/locations/refreshlocation`)
        .then(() => 
        {
            setStatus('Location updated');
            fetchData();
        });
    };

    function updateSortId(id, LocSortID) {
        setlocations(
            locations.map((item) => {
            if (item.LoctID === id) item.LocSortID = LocSortID;
            return item;
          })
        );
    };

    function updatelocationStatus(id, isActive) {
        setlocations(
            locations.map((item) => {
            if (item.LoctID === id) item.isActive = isActive;
            console.log(isActive);
            return item;
          })
        );
    };

    function handleOnDragEnd(result) {
        if(!result.destination) return;
        const items = Array.from(locations);
        const [reorderedItem] = items.splice(result.source.index, 1);
        items.splice(result.destination.index, 0, reorderedItem);
        setlocations(items);
    }

    const navigateToAddLocation = () => {
        // 👇️ navigate to /add location
        navigate('/addLocation');
    };

    useEffect(() => {
        fetchData();
    }, []);

    return (
        <div>
            <div class="d-flex flex-md-column text-start text-md-center">
                <h2>All Locations</h2> 
                <p class="text-success">{status}</p>
                <Table striped bordered hover>
                    <thead>
                        <tr>
                            <th>Location</th>
                            <th>Active</th>
                        </tr>
                    </thead>
                    <DragDropContext onDragEnd={handleOnDragEnd}>                                  
                        <Droppable droppableId='characters'>
                             {(provided) => (
                                <tbody className='characters' {...provided.droppableProps} ref={provided.innerRef}>
                                    {locations.map((locations, index) => {
                                        return (
                                            <Draggable key={locations.LoctID} draggableId={locations.LoctID.toString()} index={index}>
                                                {(provided) => (
                                                    <tr {...provided.draggableProps} {...provided.dragHandleProps} ref={provided.innerRef}>
                                                        <td>{locations.Locations == null ? "Location not Set" : locations.Locations}</td>
                                                        <td>
                                                            <input class="form-check-input" type="checkbox" checked={locations.isActive} id="flexCheckDefault" 
                                                                onChange={e => updatelocationStatus(locations.LoctID, e.target.checked)}/>
                                                        </td>
                                                    </tr> 
                                                )}
                                            </Draggable>
                                        );
                                    })}
                                </tbody>
                            )}
                        </Droppable>
                    </DragDropContext> 
                </Table> 
                <div className='allLocationBtnFloat'>
                    <div class="d-flex justify-content-end fixed-bottom">
                        <button class="btn btn-secondary" type="button" data-toggle="tooltip" data-placement="right" title="Click to refresh location"
                            onClick={refreshData}><i class="fa fa-refresh"></i> Refresh Location</button>
                        <button type="button" class="btn btn-success" data-toggle="tooltip" data-placement="right" title="Click to save location update"
                        onClick={() => {
                            locations.map((item, index) => {
                                const setR = updateLocation(item.LoctID, index, item.isActive ? 1 : 0);
                            })
                        }}> <i class="fas fa-save"></i> Save changes</button>
                        <button type="button" class="btn btn-danger" data-toggle="tooltip" data-placement="right" title="Click to Add new location"
                        onClick={navigateToAddLocation}> 
                            <i class="fa-solid fa-plus"></i> Add location</button>
                    </div>
                </div>              
            </div>
        </div>
    );
}
export default AllLocations;