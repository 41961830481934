import React, { useEffect }  from 'react';
import { Navigate } from 'react-router-dom';
import Auth from './Auth';

function Logout(props)
{
    // useEffect(()=>{
    //     document.title = "Logout";
    //     Auth.logout();
    //     console.log("from Log out class - " + Auth.isAuthenticated());
    // });
    
    // return(
    //     < Navigate to="/login"/>
    // );
    // useEffect(()=>{
    //     document.title = "Logout";
    //     Auth.logout(() => {
    //         props.history.push("/login");
    //     })
    //     console.log("from Log out class - " + Auth.isAuthenticated());
    // });
    const loggedIn = window.localStorage.getItem("isLoggedIn");
    console.log("from Log out class - " + loggedIn);
    //window.localStorage.removeItem("isLoggedIn");
    window.sessionStorage.removeItem("isLoggedIn");
    console.log("from Log out class - " + loggedIn);
    Auth.logout();
    return(
        < Navigate to="/login"/>
    );
    
}

export default Logout;