import { useState, useEffect } from "react";
import "bootstrap/dist/css/bootstrap.min.css";
import { Table } from "react-bootstrap";
import { BASE_API } from "../api/api";
import { DragDropContext, Droppable, Draggable } from "react-beautiful-dnd";
import SearchboxCard from "../AssetComponent/searchBoxCard";
import Row from "react-bootstrap/Row";
import Col from "react-bootstrap/Col";
import DropDownInput from "../AssetComponent/DropDownInput";
import { DropdownButton, ButtonGroup, Dropdown } from "react-bootstrap";
import "./allStaff401.css";

function ActiveStaffGroup(props) {
  const [employees, setEmployees] = useState([]);
  const [updateAll, setUpdateAll] = useState(false);
  const [name, setName] = useState("");
  const [chosenValue, setchosenValue] = useState();
  const [status, setStatus] = useState("");

  const [deptStatus, setDeptStatus] = useState("");

  const fetchData = async () => {
    const employeesResponse = await fetch(
      `${BASE_API}/api/employees/listall/group-page`
    ).then((res) => res.json());
    setEmployees(employeesResponse);
    console.log(employeesResponse);
  };
  const [departments, setDepartment] = useState([]);
  const fetchDeptData = async () => {
    const deptResponse = await fetch(`${BASE_API}/api/department/allDepartment`)
      .then((res) => res.json())
      .then((result) => {
        result.map((r) => {
          r.itemID = r.DeptID;
          r.itemName = r.Department;
        });
        return setDepartment([...result]);
      });
  };

  function updateStaffDept(id, dept) {
    return fetch(`${BASE_API}/api/employees/updatestaffdept/` + id + "/" + dept)
      .then((res) => res.json())
      .then(function (result) {
        if (result === "success") {
          setDeptStatus("Records updated");
          setEmployees(
            employees.map((item) => {
              if (item.EmpID === id) {
                item.NewDepartment = dept;
                item.Status = "Records updated";
              }
              return item;
            })
          );

          //setSaveStatus("Save staff changes");
          //console.log(empIdparam+' - '+isSuccess);
        } else {
          //setisSuccess(false);
          setDeptStatus("Errors updating records...");
          setEmployees(
            employees.map((item) => {
              if (item.EmpID === id) {
                item.NewDepartment = dept;
                item.Status = deptStatus;
              }
              return item;
            })
          );
        }
        return result;
      });
  }

  async function UpdateAllRow() {
    const dataRow = [];
    employees.map((item, index) => {
      let data = { id: item.EmpID, sortId: index };
      dataRow.push(data);
    });
    return await fetch(`${BASE_API}/api/employees/updatesortidgrouppage`, {
      method: "POST",
      headers: {
        "Content-Type": "application/json",
      },
      body: JSON.stringify(dataRow),
    })
      .then((res) => res.json())
      .then(function (result) {
        if (result?.UpdateCount) {
          setStatus("Records updated - " + result?.UpdateCount);
          setUpdateAll(false);
        }

        return result;
      });
  }

  async function UpdateRowDirty() {
    //const dataRow = employees.filter((item)=> item.isDirty === true);
    const dataRow = [];
    employees
      .filter((item) => item.isDirty === true)
      .map((item, index) => {
        let data = {
          id: item.EmpID,
          nameGroup: item.NameGroup,
          titleGroup: item.TitleGroup,
        };
        dataRow.push(data);
      });

    console.log(dataRow?.length);
    dataRow?.length > 0 ? UpdateDataBulk(dataRow) : "";
  }

  const UpdateDataBulk = async (dataRow) => {
    return await fetch(
      `${BASE_API}/api/employees/updateemployeegrouppagejson`,
      {
        method: "POST",
        headers: {
          "Content-Type": "application/json",
        },
        body: JSON.stringify(dataRow),
      }
    )
      .then((res) => res.json())
      .then(function (result) {
        if (result?.UpdateCount) {
          setStatus("Records updated - " + result?.UpdateCount);
          setUpdateAll(false);
        }

        return result;
      });
  };

  function handleOnDragEnd(result) {
    if (!result.destination) return;
    const items = Array.from(employees);
    const [reorderedItem] = items.splice(result.source.index, 1);
    items.splice(result.destination.index, 0, reorderedItem);
    setEmployees(items);
    setUpdateAll(true);
  }

  const searchDataHandler = (details) => {
    //setName(details.name);
    fetchDataByName(details.name);
  };

  async function fetchDataByName(nameParam) {
    const employeesResponse = await fetch(
      `${BASE_API}/api/employees/getemaployeebynameoremail/${nameParam}`
    )
      .then((res) => res.json())
      .then((result) => {
        setEmployees(
            result.map((item) => {
                item.Status = item?.isDeleted ? "Marked as Deleted" :
                item?.isHidden ? "Marked as Hidden" :
                !item?.isActive ? "Not Active" : '';
              return item;
            })
          );
      });
  }

  function updateEmployee(id, param, paramField) {
    setEmployees(
      employees.map((item) => {
        if (item.EmpID === id) {
          paramField === "name"
            ? (item.NameGroup = param)
            : (item.TitleGroup = param);
          item.isDirty = true;
        }
        return item;
      })
    );
  }

  useEffect(() => {
    fetchData();
    fetchDeptData();
  }, []);

  return (
    <div>
      <div class="d-flex flex-md-column text-start text-md-center">
        <Row>
          <Col xs={12} sm={12} md={8} lg={8}>
            <SearchboxCard
              searchDataHandler={searchDataHandler}
              pageHeader="Team 401 Group"
            />
          </Col>
        </Row>
        <div id="AllStaffPage_401">
          <Table striped bordered hover id="AllStaffPage_401_Table">
            <thead>
              <tr>
                <th id="AllStaff_Table_Th">Sort Id</th>
                <th id="AllStaff_Table_Th">Picture</th>
                <th id="AllStaff_Table_Th">Name HR</th>
                <th id="AllStaff_Table_Th">Job Title HR</th>
                <th id="AllStaff_Table_Th">Name Group</th>
                <th id="AllStaff_Table_Th">Job Title Group</th>
                <th>Department</th>
              </tr>
            </thead>
            <DragDropContext onDragEnd={handleOnDragEnd}>
              <Droppable droppableId="characters">
                {(provided) => (
                  <tbody
                    className="characters"
                    {...provided.droppableProps}
                    ref={provided.innerRef}
                  >
                    {employees.map((employee, index) => {
                      return (
                        <Draggable
                          key={employee?.EmpID}
                          draggableId={employee?.EmpID?.toString()}
                          index={index}
                        >
                          {(provided) => (
                            <tr
                              {...provided.draggableProps}
                              {...provided.dragHandleProps}
                              ref={provided.innerRef}
                            >
                              <td id="AllStaff_Table_Td">
                                {employee?.StaffSortIDGroup}
                              </td>
                              <td>
                                <img
                                  src={employee.PhotoUrl}
                                  width="55"
                                  height="45"
                                />
                              </td>
                              <td id="AllStaff_Table_Td">
                                {employee.DisplayName}
                              </td>
                              <td id="AllStaff_Table_Td_title">
                                {employee.JobTitle}
                              </td>
                              <td>
                                <input
                                  type="text"
                                  value={employee.NameGroup}
                                  id="inputParam_AllLocation401"
                                  onChange={(e) =>
                                    updateEmployee(
                                      employee.EmpID,
                                      e.target.value,
                                      "name"
                                    )
                                  }
                                />
                              </td>
                              <td>
                                <input
                                  type="text"
                                  value={employee.TitleGroup}
                                  id="inputParam_AllLocation401"
                                  onChange={(e) =>
                                    updateEmployee(
                                      employee.EmpID,
                                      e.target.value,
                                      "title"
                                    )
                                  }
                                />
                              </td>
                              <td id="AllStaff_Table_Td_Loc">
                                <DropDownInput
                                  inputValue={employee.NewDepartment}
                                  dropDownDataHandler={(e) => {
                                    updateStaffDept(employee.EmpID, e);
                                  }}
                                  itemArray={departments}
                                />
                                <p class="text-success">{employee?.Status}</p>
                              </td>
                            </tr>
                          )}
                        </Draggable>
                      );
                    })}
                  </tbody>
                )}
              </Droppable>
            </DragDropContext>
          </Table>
        </div>
        <div class="d-flex justify-content-end fixed-bottom" id="btnSaveBottom">
          <button
            type="button"
            class="btn btn-danger"
            onClick={() => {
              updateAll ? UpdateAllRow() : UpdateRowDirty();
            }}
          >
            {" "}
            <i class="fas fa-save"></i> Save changes
          </button>
        </div>
        <h4 class="d-flex justify-content-center">{status}</h4>
      </div>
    </div>
  );
}

export default ActiveStaffGroup;
