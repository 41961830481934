// import '../../App.css';
import React from 'react';
import 'bootstrap/dist/css/bootstrap.min.css';
import '@fortawesome/fontawesome-free/css/all.min.css';
import { useState, useEffect, useContext } from 'react';
import { BASE_API } from '../api/api';
import "./nav.css";
import NavDropdown from "react-bootstrap/NavDropdown";

function NavSidePage(props) {
  //const [locations, setlocations] = useState([]);

//  const fetchData = async () => {
//    const locationsResponse = await fetch(`${BASE_API}/api/locations/allLocations`).then(res => res.json());
//    setlocations(locationsResponse);
//  };

  useEffect(() => {
    //fetchData();
  }, []);

  return ( 
    <nav class="navbar navbar-expand-md navbar-light d-flex flex-md-column">
      <div className='navSideBar'>
        <ul class="navbar-nav w-100 d-flex flex-md-column text-start text-md-start">
          <li><a class="nav-link" key='Locations' href="/allLocations">Locations</a></li>
          <li><a class="nav-link" key='Locations401' href="/allLocations401Auto">Locations_401</a></li>
          {/*<li><a class="nav-link" key='jobTitles' href="/jobTitles">Job Titles</a></li>*/}
          <li><a class="nav-link" key='Department' href="/alldepartment">Department 401 Group</a></li>
          <li><a class="nav-link" key='pendingAllStaff' href="/pendingAllStaff">Pending Feed</a></li>
          
          <NavDropdown.Divider />
          <li><a class="nav-link" key='activestaffgroup' href="/activestaffgroup">Team 401 Group</a></li>
          <NavDropdown.Divider />
          <li><a class="nav-link" key='team401' href="/team401">Team 401 Active</a></li>
          <NavDropdown.Divider />
          {/*<li><a class="nav-link" key='activeAllStaff' href="/activeAllStaff">All Staff</a></li>
          <NavDropdown.Divider />*/}
          {/*<br />*/}
          {/*{props.locations.map((location, i) => {
            if(location.isActive) {
              return (
                <li><a class="nav-link" key={i} href={location.Locations.replace(/\s+/g, '')}>{location.Locations}</a></li>
              )
            }  
          })}*/}
          {/*<br /> */}
          <li><a class="nav-link" key='hiddenStaffs' href="/hiddenStaffs">Hidden Staff</a></li>
          <li><a class="nav-link" key='deletedStaffs' href="/deletedStaffs">Deleted Staff</a></li>        
        </ul>
      </div>
    </nav>
  );
}
export default NavSidePage;