import { useState, useEffect } from 'react';
import 'bootstrap/dist/css/bootstrap.min.css';
import { Table } from 'react-bootstrap';
import { BASE_API } from '../api/api';
import Enumerable  from "linq";

function AllStaffs() {

    const [employees, setEmployees] = useState([]);

    const fetchData = async () => {
        const employeesResponse = await fetch(`${BASE_API}/api/employees/401team/activestaff`).then(res => res.json()
        .then(result=> {
            
                    var getResult = Enumerable.from(result)
            .groupBy(
                g => g.Employee_Location,
                element => element,
                (key, items) => ({key, items: items.toArray()}
            )).toArray();
            //setEmployeesGroup(getResult);
            setEmployees(getResult);
            }));
        console.log(employeesResponse);
    };
    useEffect(() => {
        fetchData();
    }, []); 
    return (
        <div>
            <div class="d-flex flex-md-column text-start text-md-center"> 
                {employees.map((employee, index) => {                                      
                    return (
                        <div class="p-3 mb-2 bg-dark bg-gradient text-white">
                            <h2>{employee.key}</h2>
                            <div class="row justify-content-md-center">
                                {employee.items.map((emp, i) => {
                                    if(!emp.isHidden) {
                                        return (
                                            <div class="col col-lg-3" >
                                                <div className='teamCard'>
                                                    <div className='imgTeamCard'>
                                                        <img class="img-circle" src={emp.Employee_Image} alt="Card image cap"/>
                                                    </div> 
                                                    <div class="card-body">
                                                        <h5>{emp.Employee_Name}</h5>
                                                        <h6>{emp.Employee_Title}</h6>
                                                    </div>
                                                </div>
                                            </div>
                                        )
                                    }
                                })}
                            </div>
                        </div>
                    );  
                })}
            </div>
        </div>
    );
}
export default AllStaffs;