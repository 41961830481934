import '../../App.css';
import React from 'react';
import { useState, useEffect } from 'react';
import { BASE_API } from '../api/api';
import 'bootstrap/dist/css/bootstrap.min.css';
import '@fortawesome/fontawesome-free/css/all.min.css';
import { DropdownButton, ButtonGroup, Dropdown } from 'react-bootstrap';


function DropDownControl( {location, employee, locations}) {
    //const [locations, setlocations] = useState([]);
    const [chosenValue, setchosenValue] = useState();
    const [status, setStatus] = useState('');
    
    //const fetchData = async () => {
    //    const locationsResponse = await fetch(`${BASE_API}/api/locations/allLocations`).then(res => res.json());

    //    setlocations(locationsResponse);
    //};

    const DdlHandle= async (e)=> {
        setchosenValue(e);
        updateStaffLoc(e);
        console.log(e);
    }

    function updateStaffLoc(loc) {
        return fetch(`${BASE_API}/api/employees/updateStaffLocation/` + employee + '/' +loc)
        .then(res => res.json())
        .then(function(result){
            if(result ==="success")
            {
                setStatus('Records updated');
                //console.log(empIdparam+' - '+isSuccess);
            }
            else
            {
                //setisSuccess(false);
                setStatus('Errors updating records...');
            }
            return result;
        });
    }
    
    // function updateProc(loc)
    // {
    //     console.log(loc+' - e value');
    //     console.log(employee+' - employees');
    // }
    
    useEffect(() => {
        //fetchData();
    }, []);
    
    return ( 
        <div className="locationDropDown">
            {['down'].map((direction) => (
                <DropdownButton
                    onSelect={DdlHandle}
                    className='btn-locdropLoc'
                    as={ButtonGroup}
                    key={direction}
                    id={`dropdown-button-drop-${direction}`}
                    drop={direction}
                    variant="secondary"
                    title={`${chosenValue ? chosenValue : location} `}
                >
                    {locations?.map((location) => (
                        <Dropdown.Item eventKey={location.Locations} key={location.LoctID}>{location.Locations == null ? "Location not Set" : location.Locations}</Dropdown.Item>              
                    ))}
                </DropdownButton>
            ))}
            <p class="text-success">{status}</p>
        </div> 
    );
}
export default DropDownControl;

//{locations?.map((location) => (
//    <Dropdown.Item eventKey={Loc401 ?  location.LocationName_401Auto : location.Locations} key={location.LoctID}>{Loc401 ?  location.LocationName_401Auto :
//        location.Locations == null ? "Location not Set" : location.Locations}</Dropdown.Item>              
//))}