import React, {useState} from 'react';

function LoginForm({Login, error}) {
  const [details, setDetails] = useState({name:"", password:""});

  const submitHandler = e => {
    e.preventDefault();

    Login(details);
  }

  return (
    <form onSubmit={submitHandler}>
        <div className='form-inner'>
            <h2>Login</h2>
            {(error!='') ? (<div className='error'>{error}</div>) : ""}
            <div class="form-group">
              <label for="name">User Name </label>
              <input type="text" name="name" id="name" 
              onChange={e => setDetails({...details, name: e.target.value})} value={details.name}/>
            </div>
            <div class="form-group">
              <label for="password">Password </label>
              <input type="password" name="password" id="password" 
              onChange={e => setDetails({...details, password: e.target.value})} value={details.password}/>
            </div>
            <br/>
            <input type="submit" value="LOGIN" />
            
        </div>
    </form>
  )
}

export default LoginForm