import './App.css';
import React, { useContext, useMemo } from 'react';
import Row from "react-bootstrap/Row";
import Col from "react-bootstrap/Col";
import { useState, useEffect } from 'react';
import LogInPage from './components/LogInComponent/LogInPage';
import PendingAllStaffs from './components/ServiceComponent/PendingAllStaffs';
import ActiveAllStaffs from './components/ServiceComponent/ActiveAllStaffs'; 
import AllStaff401 from './components/ServiceComponent/AllStaff401';
import ActiveStaffGroup from './components/ServiceComponent/ActiveStaffGroup';
import StaffPageBody from './components/AssetComponent/StaffPageBody';
import HiddenAllStaffs from './components/ServiceComponent/HiddenAllStaffs';
import DeletedStaff from './components/ServiceComponent/DeletedStaff';
import AllLocations from './components/ServiceComponent/AllLocations';
import AllLocations_401 from './components/ServiceComponent/AllLocations_401';
import AddLocation from './components/ServiceComponent/AddLocation';
import AllJobTitles from './components/ServiceComponent/AllJobTitles';
import Logout from './components/LogInComponent/logout';
import Home from "./components/ServiceComponent/Home";
import Nav from './components/NavComponent/Nav';
import NavSidePage from './components/NavComponent/NavSideBar';
import {BrowserRouter as Router, Routes, Route} from 'react-router-dom';
import 'bootstrap/dist/css/bootstrap.min.css';
import AllStaffs from './components/ServiceComponent/AllStaffs';
import AllDepartment from './components/ServiceComponent/AllDepartment';
import { UserContext } from './components/UserContext';
import { BASE_API } from './components/api/api';

function App() {

  const [locations, setlocations] = useState([]);
  const [isAuthenticated, setisAuthenticated] = useState(false);
  //const loggedIn = window.localStorage.getItem("isLoggedIn");
  const loggedIn = window.sessionStorage.getItem("isLoggedIn");
  const fetchData = async () => {
  const locationsResponse = await fetch(`${BASE_API}/api/locations/allLocations`).then(res => res.json());
    setlocations(locationsResponse);
  };
  useEffect(() => {
    fetchData();
  }, []);

  return (
    <div className='App'>
        <Router>
        <UserContext.Provider value={{isAuthenticated, setisAuthenticated}}>
          <Nav isAuth={loggedIn}/> 
            <div className='content'>
                <div id='Container_Body'>
                    <Row id='Container_Body_Row'>
                    <Col xs={12} sm={12} md={12} lg={loggedIn ? 2 : 0}>
                    {(loggedIn ? < NavSidePage locations={locations}/> : "") }  
                    </Col> 
                    <Col xs={12} sm={12} md={12} lg={loggedIn ? 10 : 12}>
                    <div className='mainContainer'>   
                      <Routes>                     
                      <Route path='/home' element={ loggedIn ? <Home /> : <LogInPage />}/>
                      {/* <Route path='/home' element={  <Home />}/> */}                   
                      <Route path='/' element={ loggedIn ? <ActiveAllStaffs locations={locations}/> : <LogInPage />}/>
                      <Route path='/team401' element={ loggedIn ? <AllStaff401 locations={locations}/> : <LogInPage />}/>
                      <Route path='/activestaffgroup' element={ loggedIn ? <ActiveStaffGroup locations={locations}/> : <LogInPage />}/>
                      {/* <Route path='/' element={ Auth.isAuthenticated() ? <ActiveAllStaffs /> : <LogInPage />}/>  */}
                      <Route path='/allstaffs' element={<AllStaffs locations={locations}/>}/>
                      <Route path='/login' element={<LogInPage />}/>
                      <Route path='/logout' element={ <Logout/>}/>
                      <Route path='/pendingAllStaff' element={ loggedIn ?  <PendingAllStaffs /> : <LogInPage />}/>
                      <Route path='/activeAllStaff' element={  loggedIn ? <ActiveAllStaffs locations={locations}/> : <LogInPage />}/>
                      <Route path='/hiddenStaffs' element={loggedIn ?  <HiddenAllStaffs /> : <LogInPage />}/>
                      <Route path='/deletedStaffs' element={loggedIn ?  <DeletedStaff /> : <LogInPage />}/>
                      <Route path='/allLocations' element={loggedIn ?  <AllLocations /> : <LogInPage />}/>
                      <Route path='/allLocations401Auto' element={loggedIn ?  <AllLocations_401 /> : <LogInPage />}/>
                      <Route path='/addLocation' element={loggedIn ?  <AddLocation /> : <LogInPage />}/>
                      <Route path='/jobTitles' element={loggedIn ?<AllJobTitles/> : <LogInPage />}/>
                      <Route path='/alldepartment' element={loggedIn ?<AllDepartment/> : <LogInPage />}/>
                      { loggedIn ?
                      locations.map((location, i) => (
                      <Route path={location.Locations.replace(/\s+/g, '')} key={i} element={ <StaffPageBody location={location.Locations} locations={locations}/> }/>
                      ))
                      : <Route path='*' element={<LogInPage />}/>
                      }
                      </Routes>
                    </div>        
                    </Col> 
                    </Row>
                     
                    {/* < NavSidePage isAuth={loggedIn}/> */}
                               
                </div>         
            </div>  
            </UserContext.Provider> 
        </Router > 
      </div>  
  );
}
export default App;
