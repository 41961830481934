import { useState, useEffect } from 'react';
import 'bootstrap/dist/css/bootstrap.min.css';
import { Table } from 'react-bootstrap';
import { BASE_API } from '../api/api';
import SearchboxCard from '../AssetComponent/searchBoxCard';

function AllJobTitles() {

    const [jobTitles, setjobTitles] = useState([]);
    const [status, setStatus] = useState('');

    const fetchData = async () => {
        const jobTitlesResponse = await fetch(`${BASE_API}/api/jobTitles/allJobTitles`).then(res => res.json());
        setjobTitles(jobTitlesResponse);
    };

    function updateJobTitles(jobTitlesidparam, sortIdparam) {
        return fetch(`${BASE_API}/api/jobTitles/allJobTitles/` + jobTitlesidparam + '/' +sortIdparam)
          .then(function(result){
            return result;
        });
    }

    function updateSortId(id, SortID) {
        setjobTitles(
            jobTitles.map((item) => {
            if (item.JobTitleID === id) item.JobTitleSortID = SortID;
            return item;
          })
        );
    };

    const searchDataHandler =  details => {
        fetchDataByName(details.name);
    }

    useEffect(() => {
        fetchData();
    }, []);

    return (
        <div>
            <div class="d-flex flex-md-column text-start text-md-center"> 
                <SearchboxCard searchDataHandler={searchDataHandler} pageHeader='Job Titles' inputTextHint='Search for job title'/>
                {status}
                <ul>
                    <Table striped bordered hover>
                        <thead>
                            <tr>
                                <th>Job Title</th>
                                <th>Job Title Sort ID</th>
                            </tr>
                        </thead>
                        {jobTitles.map((jobTitle) => (
                            <tbody>
                                <tr>
                                    <td>{jobTitle.JobTitle}</td>
                                    <td><input type="text" value={jobTitle.JobTitleSortID} onChange={e => updateSortId(jobTitle.JobTitleID, e.target.value)}/></td>
                                    <td>
                                        <button type="button" class="btn btn-success" onClick={() => {
                                            updateJobTitles(jobTitle.JobTitleID, jobTitle.JobTitleSortID);
                                            setStatus("Title Updated - " + jobTitle.JobTitle);
                                        }}>Update</button> 
                                    </td>                                                        
                                </tr> 
                            </tbody>
                        ))}
                    </Table> 
                </ul>
            </div>
        </div>
    );
}
export default AllJobTitles;