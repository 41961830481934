import { useState, useEffect } from "react";
import "bootstrap/dist/css/bootstrap.min.css";
import { Table } from "react-bootstrap";
import { BASE_API } from "../api/api";
import { DragDropContext, Droppable, Draggable } from "react-beautiful-dnd";
import SearchboxCard from "../AssetComponent/searchBoxCard";
import DropDownControl from "../AssetComponent/DropDownControl";
import "./allStaff401.css";

function DeletedStaff(props) {
  const [employees, setEmployees] = useState([]);
  const [name, setName] = useState("");
  const [status, setStatus] = useState("");

  const fetchData = async () => {
    const employeesResponse = await fetch(
      `${BASE_API}/api/employees/deleted`
    ).then((res) => res.json());
    setEmployees(employeesResponse);
    console.log(employeesResponse);
  };


  function updateEmployeeSetActiveFalse(param) {
    return fetch(`${BASE_API}/api/employees/setActiveFalse/` + param).then(
      function (result) {
        return result;
      }
    );
  }

  function updateemployeeSetActiveTrue(param) {
    return fetch(`${BASE_API}/api/employees/setActiveTrue/` + param).then(
      function (result) {
        return result;
      }
    );
  }

  function updateStaff401(empIdparam, sortIdparam, name, loc, title) {
    return fetch(
      `${BASE_API}/api/employees/updateStaff401/` +
        empIdparam +
        "/" +
        sortIdparam
        +
        "/" +
        name
        +
        "/" +
        loc
        +
        "/" +
        title
    )
      .then((res) => res.json())
      .then(function (result) {
        if (result === "success") {
          setStatus("Records updated");
        } else {
          setisSuccess(false);
        }
        return result;
      });
  }

  function handleOnDragEnd(result) {
    if (!result.destination) return;
    const items = Array.from(employees);
    const [reorderedItem] = items.splice(result.source.index, 1);
    items.splice(result.destination.index, 0, reorderedItem);
    setEmployees(items);
  }

  function changeButtonText(id, isHidden) {
    setEmployees(
      employees.map((item) => {
        if (item.EmpID === id) item.isHidden = isHidden;
        return item;
      })
    );
  }

  const searchDataHandler = (details) => {
    console.log(details.name);
    setName(details.name);
    //fetchDataByName(details.name);
  };

  function updateEmployeeStatus(id, isActive) {
    setEmployees(
        employees.map((item) => {
        if (item.EmpID === id)
        {
            item.isActive_401Auto = isActive;
            item.isDirty = true;
        } 
        return item;
      })
    );
  }

  async function updateEmployeeDeletStatus(dataRow) {
    return await fetch(`${BASE_API}/api/employees/updatedeletestatus401auto`, {
        method: "POST",
        headers: {
          "Content-Type": "application/json",
        },
        body: JSON.stringify(dataRow),
      })
        .then((res) => res.json())
        .then(function (result) {
          if (result?.UpdateCount) {
            setStatus("Records updated - " + result?.UpdateCount);
            setUpdateAll(false);
          }
          return result;
        })
}

function updateDeleteStatus(id) {
    setEmployees(
        employees.map((item) => {
        if (item.EmpID === id)
        {
            item.isDeleted = false;
            item.isActive = true;
            item.isHidden = false;
        } 
        return item;
      })
    );
    let data = { id: id, isDeleted: false, isActive: true, isHidden: false };
    updateEmployeeDeletStatus(data); //delete false = 0, true = 1
  }

  useEffect(() => {
    fetchData();
  }, []);

  return (
    <div>
      <div class="d-flex flex-md-column text-start text-md-center">
        <SearchboxCard
          searchDataHandler={searchDataHandler}
          pageHeader="Deleted Staffs"
        />
        <div id="AllStaffPage_401">
        <Table striped bordered hover >
          <thead>
            <tr>
              <th>Picture</th>
              <th>Name</th>
              <th>Job Title</th>
              <th>Location HR</th>
              {/*{!employee.isDeleted &&<th>401 Location</th>}*/}
              {/*<th>Active</th>*/}
            </tr>
          </thead>
          <DragDropContext onDragEnd={handleOnDragEnd}>
            <Droppable droppableId="characters">
              {(provided) => (
                <tbody
                  className="characters"
                  {...provided.droppableProps}
                  ref={provided.innerRef}
                >
                  {employees.filter((item) => {
            if (name === '') {
              return item;
            } else {
                return item.DisplayName === name;
            }
          }).map((employee, index) => {
                    return (
                          <tr
                            {...provided.draggableProps}
                            {...provided.dragHandleProps}
                            ref={provided.innerRef}
                          >
                            <td>
                              <img
                                src={employee.PhotoUrl}
                                width="55"
                                height="45"
                              />
                            </td>
                            <td>{employee.DisplayName}</td>
                            <td>{employee.JobTitle}</td>
                            <td>{employee.Location}</td>
                            <td>
                            {employee.isDeleted ? (
                                    <button
                                      type="button"
                                      class={"btn btn-danger"}
                                      onClick={() => {
                                        updateDeleteStatus(employee.EmpID);
                                      }}
                                    ><i class="fa-solid fa-eye-slash"></i>
                                    </button>
                                  ) : (
                                    ""
                                  )}
                            </td>

                            {/*{!employee.isDeleted && <td>
                              <button
                                type="button"
                                class="btn btn-success"
                                onClick={() => {
                                  employee.isHidden == 0
                                    ? updateEmployeeSetActiveFalse(
                                        employee.EmpID
                                      )
                                    : updateemployeeSetActiveTrue(
                                        employee.EmpID
                                      );
                                  changeButtonText(
                                    employee.EmpID,
                                    employee.isHidden == 0 ? 1 : 0
                                  );
                                }}
                              >
                                {employee.isHidden == 1 ? <i  class="fa fa-trash"></i> : <i class="fa-solid fa-eye"></i>}
                              </button>
                            </td>
                            }
                            <td>
                                            {employee.isDeleted ?
                                            <button type="button" class={"btn btn-success"} 
                                            data-toggle="tooltip" data-placement="top" title="Click to Add staff as Active"
                                            onClick={() => 
                                                {
                                                    updateDeleteStatus(employee.EmpID);
                                                }}><i class="fa-solid fa-user-plus"></i></button>
                                                : ''
                                            }
                                    </td> */}
                          </tr>
                    );
                  })}
                </tbody>
              )}
            </Droppable>
          </DragDropContext>
        </Table>
        </div>
        <div class="d-flex justify-content-end fixed-bottom">
          <button
            type="button"
            class="btn btn-danger"
            onClick={() => {
              employees.map((item, index) => {
                item.isDirty ?
                updateStaff401(item.EmpID, index, item.Name401, item.Location401, item.Title401)
                : '';
                //const setR = updateStaffSortIDByLoc(item.EmpID, index);
              });
            }}
          >
            {" "}
            <i class="fas fa-save"></i> Save changes
          </button>
        </div>
        <h4 class="d-flex justify-content-center">{status}</h4>
      </div>
    </div>
  );
}


export default DeletedStaff