import { useState, useEffect } from 'react';
import 'bootstrap/dist/css/bootstrap.min.css';
import { Table } from 'react-bootstrap';
import { BASE_API } from '../api/api';
import {DragDropContext, Droppable, Draggable} from 'react-beautiful-dnd';
import SearchboxCard from '../AssetComponent/searchBoxCard';
import DropDownControl from '../AssetComponent/DropDownControl';

function ActiveAllStaffs(props) {

    const [employees, setEmployees] = useState([]);
    const [name, setName] = useState("");
    const [status, setStatus] = useState("");

    const fetchData = async () => {
        console.log(`${BASE_API}/api/employees/active`);
        const employeesResponse = await fetch(`${BASE_API}/api/employees/active`).then(res => res.json());
        setEmployees(employeesResponse);
    };

    async function fetchDataByName(nameParam) {
        const employeesResponse = await fetch(`${BASE_API}/api/employees/active` + '/'+ nameParam )
        .then(res => res.json());
        setEmployees(employeesResponse);
    };

    function updateEmployeeSetActiveFalse(param) {
        return fetch(`${BASE_API}/api/employees/setActiveFalse/` + param)
          .then(function(result){
            return result;
        });
    }

    function updateemployeeSetActiveTrue(param) {
        return fetch(`${BASE_API}/api/employees/setActiveTrue/` + param)
          .then(function(result){
            return result;
        });
    }

    function updateStaffSortIDByLoc(empIdparam, sortIdparam) {
        return fetch(`${BASE_API}/api/employees/updateStaffSortIDByLoc/` + empIdparam + '/' +sortIdparam)
        .then(res => res.json())
          .then(function(result){
            if(result ==="success")
            {
                setStatus('Records updated');
            }
            else
            {
                setisSuccess(false);
            }
            return result;
        });
    }

    function handleOnDragEnd(result)
    {
        if(!result.destination) return;
        const items = Array.from(employees);
        const [reorderedItem] = items.splice(result.source.index, 1);
        items.splice(result.destination.index, 0, reorderedItem);
        setEmployees(items);
    }

    function changeButtonText(id, isHidden) {
        setEmployees(
            employees.map((item) => {
            if (item.EmpID === id) item.isHidden = isHidden;
            return item;
          })
        );
    };

    const searchDataHandler =  details => {
        console.log(details.name);
        fetchDataByName(details.name);
    }

    useEffect(() => {
        fetchData();
    }, []);
    
    return (
        <div>
            <div class="d-flex flex-md-column text-start text-md-center">     
                <SearchboxCard searchDataHandler={searchDataHandler} pageHeader='All Staff'/>
                <Table striped bordered hover>
                    <thead>
                        <tr>
                            <th>Picture</th>
                            <th>First Name</th>
                            <th>Last Name</th>
                            <th>Email</th>
                            <th>Job Title</th>
                            <th>Location</th>
                            <th>new Location</th>
                        </tr>
                    </thead>
                    <DragDropContext onDragEnd={handleOnDragEnd}>                                  
                        <Droppable droppableId='characters'>
                            {(provided) => (
                                <tbody className='characters' {...provided.droppableProps} ref={provided.innerRef}>
                                    {employees.map((employee, index) => {
                                        return (
                                            <Draggable key={employee.EmpID} draggableId={employee.EmpID.toString()} index={index}>
                                                {(provided) => (
                                                    <tr {...provided.draggableProps} {...provided.dragHandleProps} ref={provided.innerRef}>
                                                        <td><img src={employee.PhotoUrl} width="105" height="90"   /></td>
                                                        <td>{employee.FirstName}</td>
                                                        <td>{employee.LastName}</td>
                                                        <td>{employee.WorkEmail}</td>     
                                                        <td>{employee.JobTitle}</td>
                                                        <td>{employee.Location}</td>
                                                        <td><DropDownControl location={employee.NewLocation} employee = {employee.EmpID} locations={props.locations}/></td>
                                                        <td>
                                                            <button type="button" class="btn btn-success" onClick={() => {
                                                                employee.isHidden == 0 ?
                                                                updateEmployeeSetActiveFalse(employee.EmpID) : updateemployeeSetActiveTrue(employee.EmpID);
                                                                changeButtonText(employee.EmpID, employee.isHidden == 0 ? 1 : 0);
                                                            }}>{employee.isHidden == 1 ? "Hidden from Website" : "Showing"}</button>
                                                        </td>                                                                                                    
                                                    </tr>
                                                )}
                                            </Draggable>
                                        );
                                    })}
                                </tbody>
                            )}
                        </Droppable>
                    </DragDropContext> 
                </Table>
                <div class="d-flex justify-content-end fixed-bottom">
                    <button type="button" class="btn btn-danger" onClick={() => {
                        employees.map((item, index) => {
                            const setR = updateStaffSortIDByLoc(item.EmpID, index);
                        })
                    }}> <i class="fas fa-save"></i> Save changes</button>
                </div>
                <h4 class="d-flex justify-content-center">{status}</h4> 
            </div>
        </div>
    );
}
export default ActiveAllStaffs;

