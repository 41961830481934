import { useState, useEffect } from 'react';
import React from "react";
import 'bootstrap/dist/css/bootstrap.min.css';
import { BASE_API } from '../api/api';
import { Table } from 'react-bootstrap';
import SearchboxCard from '../AssetComponent/searchBoxCard';
import DropDownControl from '../AssetComponent/DropDownControl';

function PendingAllStaffs() {

    const [employees, setEmployees] = useState([]);
    const [searchedArray, setSearchedArray] = useState(employees);
    const [searchString, setSearchString] = useState("");
    const [locations, setlocations] = useState([]);
    const [updateStatus, setUpdateStatus] = useState([]);
    const [dataCount, setDataCount] = useState("");
    

    const refreshData = async () => {
        const employeesResponse = await fetch(`${BASE_API}/api/fetchemployeedatafrombamboo`).then(res => res.json());
        datCountProc(employeesResponse);
        setEmployees(employeesResponse);

    };

    const fetchLocationData = async () => {
        const locationsResponse = await fetch(`${BASE_API}/api/locations/allLocations`).then(res => res.json());
        
        setlocations(locationsResponse);
    };

    const fetchData = async () => {
        const employeesResponse = await fetch(`${BASE_API}/api/employees/pending`).then(res => res.json());
        datCountProc(employeesResponse);
        setEmployees(employeesResponse);
        console.log(employeesResponse);
    };

    const datCountProc = (emp) => {
        let count = 0;
        emp.map((item) => {
            count++;
          })
        setDataCount(count);
    }

    function updateemployeeSetActiveTrue(param) {
        return fetch(`${BASE_API}/api/employees/setActiveTrue/` + param)
          .then(function(result){
            return result;
        });
    }

    function updateEmployeeSetActiveFalse(param) {
        return fetch(`${BASE_API}/api/employees/setActiveFalse/` + param)
          .then(function(result){
            return result;
        });
    }

    function updateEmployeeDeleteStatus(param, status) {
        return fetch(`${BASE_API}/api/employees/updatedeletestatus/` + param + "/" + status)
          .then(function(result){
            return result;
        });
    }

    function updateDeleteStatus(id) {
      setEmployees(
        employees.map((item) => {
          if (item.EmpID === id) {
            item.isDeleted = true;
            item.isActive = false;
            item.isHidden = true;
          }
          return item;
        })
      );

      updateEmployeeDeleteStatus(id, 1); //delete false = 0, true = 1
    }

    function updateEmployeePendingStatus(param, status) {
        return fetch(
          `${BASE_API}/api/employees/updatependingstatus/` + param + "/" + status
        ).then(function (result) {
          return result;
        });
      }

    function updatePendingStatus(id, status) {
      console.log(status);
      setEmployees(
        employees.map((item) => {
          if (item.EmpID === id) {
            item.isPending = status;
          }
          return item;
        })
      );

      updateEmployeePendingStatus(id, status); //delete false = 0, true = 1
    }

    function updateActiveStatus(id, status) {
      console.log(status);
      setEmployees(
        employees.map((item) => {
          if (item.EmpID === id) {
            item.isActive = status;
          }
          return item;
        })
      );

      updateEmployeeActiveStatus(id, status); //delete false = 0, true = 1
    }

    function updateEmployeeActiveStatus(param, status) {
      return fetch(
        `${BASE_API}/api/employees/updateactivestatus/` + param + "/" + status
      ).then(function (result) {
        return result;
      });
    }

    async function fetchDataByName(param) {
        const employeesResponse = await fetch(`${BASE_API}/api/employees/pending/` +  param)
        .then(res => res.json());
        datCountProc(employeesResponse);
        setEmployees(employeesResponse);
        console.log(employeesResponse);
    };

    const searchDataHandler =  details => {
        fetchDataByName(details.name);
    }

    useEffect(() => {
        fetchData();
        fetchLocationData();
        }, [])
      
    function changeButtonText(id, isHidden) {
        setEmployees(
            employees.map((item) => {
            if (item.EmpID === id) 
            {
                item.isHidden = isHidden;
                if(item.isActive == 0)
                {
                    item.isActive = 1;
                    setDataCount(dataCount - 1)
                } 
            }  
            return item;
          })
        );
    };

    return (
        <div>
            <div class="d-flex flex-md-column text-start text-md-center">
                <SearchboxCard searchDataHandler={searchDataHandler} pageHeader='Pending Feed'/> 
                <div id="PendingStaffPage"> 
                <Table striped bordered hover>
                    <thead>
                        <tr>
                            <th>Picture</th>
                            <th>Name</th>
                            <th>Job Title</th>
                            <th>Location</th>
                            <th>new Location</th>
                            <th>Pending</th>
                            <th>Active</th>
                            <th>
                                <button class="btn btn-secondary" type="button" data-toggle="tooltip" data-placement="right" title="Click to refresh Pending Feed"
                                onClick={() =>
                                    {
                                        setUpdateStatus('Loading.....');
                                        refreshData().then(()=>setUpdateStatus('Done'));
                                    }}><i class="fa fa-refresh" aria-hidden="true"></i></button><br />
                                    <text>{updateStatus}</text>
                            </th>
                        </tr>
                    </thead>
                    {employees.map((employee, i) => (
                        <tbody>
                            <tr key={employee.EmpID}>
                                    <td><img src={employee.PhotoUrl} width="75" height="60" /></td>
                                    <td>{employee.DisplayName}</td>
                                    <td>{employee.JobTitle}</td>
                                    <td>{employee.Location}</td>
                                    <td><DropDownControl location={employee.NewLocation} employee = {employee.EmpID} locations={locations}/></td>
                                    {/*<td>
                                    {employee.LastUpdate === null ? <i class="fa-solid fa-user-secret"></i> :''}
                                    {" "}<button type="button" class={employee.isActive == 0 && employee.isHidden == 1 ?  "btn btn-warning" : 
                                        employee.isHidden == 1 ? "btn btn-danger" : "btn btn-success"} onClick={() => 
                                            {
                                                
                                                employee.isHidden == 0 ?
                                                updateEmployeeSetActiveFalse(employee.EmpID) : 
                                                updateemployeeSetActiveTrue(employee.EmpID);
                                                changeButtonText(employee.EmpID, employee.isHidden == 0 ? 1 : 0);
                                                //employee.isActive == 1 ? setDataCount(dataCount - 1) : setDataCount(dataCount);
                                                // setBtnText('Showing');
                                            }}>{employee.isActive == 0 && employee.isHidden == 1 ? <i class="fa-solid fa-hand"></i> : 
                                            employee.isHidden == 1 ? <i class="fa-solid fa-eye-slash"></i> :  <i class="fa-solid fa-eye"></i>}</button>
                                            
                                    </td>*/}
                                    <td>
                                    {employee.LastUpdate === null ? <i class="fa-solid fa-user-secret"></i> :''}
                                    {" "}<button type="button" class={employee?.isPending == 1 ? "btn btn-danger" : "btn btn-success"} onClick={() => 
                                            {
                                                updatePendingStatus(employee.EmpID, employee.isPending ? 0 : 1);
                                            }}>{employee?.isPending == 1 ? <i class="fa-solid fa-eye-slash"></i> :  <i class="fa-solid fa-eye"></i>}</button>
                                            
                                    </td>
                                    <td>
                                    {" "}<button type="button" class={employee?.isActive == 0 ? "btn btn-danger" : "btn btn-success"} onClick={() => 
                                            {
                                                updateActiveStatus(employee.EmpID, employee.isActive ? 0 : 1);
                                            }}>{employee?.isActive == 0 ? <i class="fa-solid fa-eye-slash"></i> :  <i class="fa-solid fa-eye"></i>}</button>
                                            
                                    </td>
                                    <td>
                                            {!employee.isDeleted ?
                                            <button type="button" class={"btn btn-danger"} 
                                            onClick={() => 
                                                {
                                                    updateDeleteStatus(employee.EmpID);
                                                }}><i class="fa fa-trash" aria-hidden="true"></i></button>
                                                : ''
                                            }
                                    </td>                                                         
                            </tr>
                            
                        </tbody>
                    ))}
                </Table> 
                </div>
                <div className='allLocationBtnFloat'>
                    <div class="d-flex justify-content-start fixed-top" id='allLocationBtnFloat'>
                    <h5 className='pendinngCountDiv'>Pending {dataCount}</h5>
                    </div>
                </div>
            </div>
        </div>
    );
}
export default PendingAllStaffs;