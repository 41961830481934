import '../../App.css';
import React from 'react';
import { useState } from 'react';
import 'bootstrap/dist/css/bootstrap.min.css';
import '@fortawesome/fontawesome-free/css/all.min.css';

function SearchboxCard( {searchDataHandler, pageHeader, inputTextHint}) {
    const [details, setDetails] = useState({name:"", password:""});

    return ( 
        <div class="d-flex justify-content-around">
        <h2>{pageHeader}</h2>
        <div class="d-flex justify-content-around">               
            <input type="text" class="form-control" placeholder={inputTextHint ? inputTextHint : "Search for employee" }
                onChange={e => setDetails({...details, name: e.target.value})} value={details.name}/>
            <div class="input-group-append">
                <button class="btn btn-secondary" type="button" data-toggle="tooltip" data-placement="right" 
                    title={inputTextHint ? "" : "Click to search by name/location/dept" }
                    onClick={() => 
                    {
                        console.log({details});                                
                        searchDataHandler(details);                                                           
                    }}>                                
                    <i class="fa fa-search"></i>
                </button>
            </div>
        </div>
    </div> 
        
    );
}
export default SearchboxCard;