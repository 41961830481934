import { useState, useEffect } from 'react';
import 'bootstrap/dist/css/bootstrap.min.css';
import { BASE_API } from '../api/api';
import { Table } from 'react-bootstrap';
import React from 'react';
import { useNavigate } from 'react-router-dom';
import {DragDropContext, Droppable, Draggable} from 'react-beautiful-dnd';

function AllDepartment() {

    const [departments, setDepartment] = useState([]);
    const [status, setStatus] = useState('');
    const navigate = useNavigate();

    const fetchData = async () => {
        const deptResponse = await fetch(`${BASE_API}/api/department/allDepartment`).then(res => res.json());
        setDepartment(deptResponse);
        console.log(deptResponse);
    };

    function updateLocation(deptIdparam, sortIdparam, isActiveparam) {
        return fetch(`${BASE_API}/api/department/allDepartment/` + deptIdparam + '/' +sortIdparam+ '/' + isActiveparam)
        .then(res => res.json())
        .then(function(result) {
            console.log(result);
            if(result ==="success")
            {
                setStatus('Records updated');
            }
            else
            {
                setStatus('Errors updating records...');
            }
            return result;
        });
    }


    function updatelocationStatus(id, isActive) {
        setDepartment(
            departments.map((item) => {
            if (item.DeptID === id) item.isActive = isActive;
            console.log(isActive);
            return item;
          })
        );
    };

    function handleOnDragEnd(result) {
        if(!result.destination) return;
        const items = Array.from(departments);
        const [reorderedItem] = items.splice(result.source.index, 1);
        items.splice(result.destination.index, 0, reorderedItem);
        setDepartment(items);
    }
    useEffect(() => {
        fetchData();
    }, []);

    return (
        <div>
            <div class="d-flex flex-md-column text-start text-md-center">
                <h2>All Department</h2> 
                <p class="text-success">{status}</p>
                <Table striped bordered hover>
                    <thead>
                        <tr>
                            <th>Department</th>
                            <th>Active</th>
                        </tr>
                    </thead>
                    <DragDropContext onDragEnd={handleOnDragEnd}>                                  
                        <Droppable droppableId='characters'>
                             {(provided) => (
                                <tbody className='characters' {...provided.droppableProps} ref={provided.innerRef}>
                                    {departments.map((dept, index) => {
                                        return (
                                            <Draggable key={dept.DeptID} draggableId={dept.DeptID.toString()} index={index}>
                                                {(provided) => (
                                                    <tr {...provided.draggableProps} {...provided.dragHandleProps} ref={provided.innerRef}>
                                                        <td>{dept.Department == null ? "Location not Set" : dept.Department}</td>
                                                        <td>
                                                            <input class="form-check-input" type="checkbox" checked={dept.isActive} id="flexCheckDefault" 
                                                                onChange={e => updatelocationStatus(dept.DeptID, e.target.checked)}/>
                                                        </td>
                                                    </tr> 
                                                )}
                                            </Draggable>
                                        );
                                    })}
                                </tbody>
                            )}
                        </Droppable>
                    </DragDropContext> 
                </Table> 
                <div className='allLocationBtnFloat'>
                    <div class="d-flex justify-content-end fixed-bottom">
                        <button type="button" class="btn btn-success" data-toggle="tooltip" data-placement="right" title="Click to save location update"
                        onClick={() => {
                            departments.map((item, index) => {
                                const setR = updateLocation(item.DeptID, index, item.isActive ? 1 : 0);
                            })
                        }}> <i class="fas fa-save"></i> Save changes</button>

                    </div>
                </div>              
            </div>
        </div>
    );
}

export default AllDepartment